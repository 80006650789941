import React from "react";

// import { ReactComponent } from "./blackRiverLogo.svg";
import { default as image } from "./unitedRepublicOfTanzaniaLogo.jpeg";

export const BlackRiverLogo = React.forwardRef(
  // (props: React.ComponentPropsWithRef<"svg">, ref: React.Ref<SVGSVGElement>) => {
  //   return <ReactComponent ref={ref} {...props} />;
  // }
  (props: React.ComponentPropsWithRef<"img">, ref: React.Ref<HTMLImageElement>) => {
    return <img src={image} ref={ref} {...props} />;
  }
);
